import React from "react";
import { ContainerLabel, Label } from "../../Ui/Style/GeneralStyle";

const TitleDinner =() =>{

    return  ( <ContainerLabel >
                <Label >Desayuno incluido</Label>
            </ContainerLabel>)

}

export default TitleDinner